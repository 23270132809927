import {Component, ElementRef, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit {

  constructor() {
  }
  config = {
    pagination: {
    },
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    loop: true
  };
  currentPos = 0;
  imagePath = environment.url + '/assets/image/testimonials';
  animationShow = true;
  testimonials = [
    {
      image: this.imagePath + '/olek-150x150.jpeg',
      name: 'Olek Shestakov',
      text: '<p>We enjoyed working with Kinect.PRO team. Development was done on time and within the budget. Software was deployed with the ' +
        'dependable high quality. </p><p>We look forward to continue working with Anton and Kinect.PRO team in the future. </p>',
      company: 'CEO @ Livegenic Inc'
    }, {
      image: this.imagePath + '/john-cooper-1-150x150.jpg',
      name: 'John E. Cooper',
      text: '<p>The project with Kinect.PRO was very well managed, with excellent communication, on-time, on-budget and with a great ' +
        'result. </p><p>Anton &amp; Maks takes it upon himself to ensure that jobs are completed to satisfaction. Highly responsive and ' +
        'very quick on the turnaround!</p>',
      company: 'Co-founder @ WEEX'
    }, {
      image: this.imagePath + '/about_new_design_group_dmitri.jpg',
      name: 'Dmitri V. Logounov',
      text: '<p>Sergei and Max ( Kinect.PRO) are true professionals and a pleasure to work with. They are reliable, dependable and ' +
        'go extra mile in servicing their clients.</p><p>I would absolutely recommend Kinect.PRO  to anyone looking for a first class mobile app design company.</p>',
      company: 'CEO @ New Design Group Inc'
    }, {
      image: this.imagePath + '/andrey-alexandrov.jpg',
      name: 'Andrey Alexandrov',
      text: '<p>Kinect.PRO helped us to build effective distributed teams for our projects. Their staffing & recruitment services are something unique for our market. We appreciate the complete transparency, honesty, and individual approach to each of our vacancies.</p><p>Will definitely work with them again in the future for upcoming projects. Would highly recommend!</p>',
      company: 'President, CEO @ UUUDesign'
    }
  ];

  ngOnInit() {
  }
}
