<ngx-spinner bdColor="white" color="#3face4"></ngx-spinner>
<body *ngIf="isLoading">
  <app-header></app-header>
  <app-expertise></app-expertise>
  <app-our-work></app-our-work>
  <app-testimonials></app-testimonials>
  <app-about></app-about>
  <hr>
  <app-technologies></app-technologies>
  <hr>
  <app-our-team></app-our-team>
  <app-footer></app-footer>
</body>
