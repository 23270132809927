<footer class="p-releative z-index-2" id="footer">
  <div class="container-fluid container-lg">
    <div class="row">
      <div class="col-12">
        <div class="mb-5 text-left text-danger right-line">
          <p>Contact us</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5  col-12">
        <div class="widget widget_text">
          <div class="textwidget">
            <div class="black text-left">
              <p>20 Camden Street, Suite 200<br>
                Toronto, ON Canada<br>
                M5V 1V1</p>
            </div>
          </div>
        </div>
        <div class="widget widget_text">
          <div class="textwidget">
            <div class="black text-left">
              <p>
                Vasylivskyi lane 10,<br>
                Kropyvnytskyi, Ukraine,<br>
                25006
              </p>
            </div>
          </div>
        </div>
        <div id="text-11" class="widget widget_text">
          <div class="textwidget">
            <div class="icon-phone"><a href="tel:+16478779670">+1 647 877 9670</a></div>
            <div class="icon-email"><a href="mailto:info@kinect.pro">info@kinect.pro</a></div>
          </div>
        </div>

      </div>
      <div class="col-lg-6 offset-lg-1 pt-5 pt-lg-0">
        <div role="form" class="wpcf7" lang="en-US" dir="ltr">
          <div class="screen-reader-response"></div>
          <form [formGroup]="mailForm" novalidate #formDirective="ngForm" (ngSubmit)="sendMail($event, formDirective)">
            <div class="row">
              <div class="col-6">
                <p><label> Your name *<br>
                  <span class="your-name">
                    <input required formControlName="name" type="text" name="your-name" value="" size="40">
                    <div *ngIf="f.name.invalid && (f.name.dirty ||f.name.touched || isTrySending)">
                      <span *ngIf="f.name.errors.required" class="validation-error">The field is required.</span>
                    </div>
                  </span> </label></p>
              </div>
              <div class="col-6">
                <p><label> Your email *<br>
                  <span class="your-email">
                    <input formControlName="email" type="email" name="your-email" size="40">
                      <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched || isTrySending)">
                        <span *ngIf="f.email.errors.required" class="validation-error validation-error-required">The field is required.</span>
                        <span *ngIf="f.email.errors.email" class="validation-error validation-error-invalid">The field must be valid email.</span>
                      </div>
                  </span> </label></p>
              </div>
              <div class="col-12">
                <p><label> Your message *<br>
                  <span class=" your-message">
                    <textarea formControlName="message" name="your-message" cols="40" rows="3"></textarea>
                    <div *ngIf="f.message.invalid && (f.message.dirty || f.message.touched || isTrySending)">
                      <span *ngIf="f.message.errors.required" class="validation-error">The field is required.</span>
                    </div>
                  </span>
                </label></p>
              </div>
              <div class="col-12">
                <p>
                  <span *ngIf="isSuccess" class="text-success">Form sending success</span>
                  <span *ngIf="isError" class="text-danger">Something went wrong! Try again later.</span>
                </p>
                <p>
                  <button type="submit" class="" [disabled]="this.isSending">
                    <span *ngIf="!this.isSending" class="ajax-loader">Send Message</span>
                    <span *ngIf="this.isSending" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
                    <span *ngIf="this.isSending">Loading...</span>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <!--        <div id="media_image-3" class="media_image">-->
        <!--          <img width="320" height="50"-->
        <!--               src="assets/uploads/2019/04/stacks-logo.png"-->
        <!--               class="image wp-image-286  attachment-full size-full"-->
        <!--               alt="" style="max-width: 100%; height: auto;"-->
        <!--               srcset="assets/uploads/2019/04/stacks-logo.png 320w,-->
        <!--                      ~/assets/uploads/2019/04/stacks-logo-150x23.png 150w,-->
        <!--                      ~src/assets/uploads/2019/04/stacks-logo-300x47.png 300w,-->
        <!--                      ~src/assets/uploads/2019/04/stacks-logo-250x39.png 250w"-->
        <!--               sizes="(max-width: 320px) 100vw, 320px">-->
        <!--        </div>-->
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <ul id="bottom-nav" class="nav nav-pills bottom-menu">
          <li id="menu-item-282"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-30 current_page_item menu-item-282">
            <a href="/" aria-current="page">Home</a></li>
          <li id="menu-item-470" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-470"><a
            href="https://www.facebook.com/kinect.pro">News</a></li>
          <li id="menu-item-471" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-471"><a
            href="https://www.upwork.com/ag/kinectpro/">Upwork</a></li>
        </ul>
      </div>
      <div class="col-md-6 col-12">
        <p class="copyright float-right">
          All Rights Reserved © 2013 - 2020 </p>
      </div>
    </div>
  </div>
</footer>
