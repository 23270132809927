import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-our-work',
  templateUrl: './our-work.component.html',
  styleUrls: ['./our-work.component.scss']
})
export class OurWorkComponent implements OnInit {

  constructor() {
  }
  imagePath = environment.url +  '/assets/image/works';

  works = [
    {
      title: 'The overflowing stock of unsold goods',
      description: 'Chinese company distributes their products only through ebay and retail. Commission is too high.',
      image: this.imagePath + '/1.jpg',
      challenge: ' 2 months before Christmas. Warehouse is full. Sales plan is not met.',
      result: '250K installs of shopping app, Nov-Jan. $20K extra sales made via app and website.'
    }, {
      title: 'Medical device revival with new software and UX',
      description: 'US clinic bought software and wearable hardware for patient monitoring. ',
      image: this.imagePath + '/medical-device.jpeg',
      challenge: 'Technicians experience a lot of technical issues. Patients need to redo measurements often. Clinic have to stop using the product.',
      result: 'New product is going live in 9 months with stable hardware work, long battery lifetime, clear UX for patients, new back-end.'
    }, {
      title: 'From startup to a big international enterprise',
      description: 'The idea was brilliant. It quickly found first clients willing to integrate it into their business.',
      image: this.imagePath + '/45437-motor-insurance-dna.jpg',
      challenge: ' Any developer pushed to ship a product quickly knows how bad and non-maintainable design is, but it\'s no time to do it right.',
      result: ' 50+ white label apps. Automated building process. Well designed and easily integrated SDK. High availability.'
    }, {
      title: 'An important event is coming… Not ready?',
      description: 'Startup is rising Series A investments, the seed capital has already been provided. Presentations to investors come in two months.',
      image: this.imagePath + '/investor-presentation.jpg',
      challenge: 'The team still remains to complete so much to demonstrate the product at its best. But they are little shorthanded.\n',
      result: 'Our iOS developer joined the team in a few days. For 2 months, all had time, and even more. The product looks great.\n'
    }
  ];


  config = {
    pagination: {
      el: '.swiper-pagination',
      // type: 'bullets',
      clickable: true,
    },
    loop: true
  };

  ngOnInit() {
  }
}
