<section class="mb-5" id="expertise">
  <div class="container-fluid container-lg">
      <h3 class="text-center p-5" appFadeInDown>Expertise</h3>

    <div class="row">
      <div class="col-md-6 col-12" appFlipInY>
        <div class="row">
          <div class="col-12 p-0">
            <div class="item-wrapper size-1" [style.background]="'url(' + items[0].image + ')'">
              <div class="item-back"></div>
              <div class="item-info">
                <div class="item-title">
                  <h2 class="ml-4 mt-4">{{items[0].title}}</h2>
                </div>
                <div class="animated-line ml-4 mt-4"></div>
                <div class="item-description mx-4 mt-4">
                  <span>{{items[0].description}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 p-0">
            <div class="item-wrapper size-2" [style.background]="'url(' + items[1].image + ')'">
              <div class="item-back"></div>
              <div class="item-info">

                <div class="item-title">
                  <h2 class="ml-3 mt-3">{{items[1].title}}</h2>
                </div>
                <div class="animated-line ml-3 mt-3"></div>
                <div class="item-description mx-3 mt-3">
                  <span>{{items[1].description}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 p-0">
            <div class="item-wrapper size-2" [style.background]="'url(' + items[2].image + ')'">
              <div class="item-back"></div>
              <div class="item-info">

                <div class="item-title">
                  <h2 class="ml-3 mt-3">{{items[2].title}}</h2>
                </div>
                <div class="animated-line ml-3 mt-3"></div>
                <div class="item-description mx-3 mt-3">
                  <span>{{items[2].description}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12" appFlipInY>
        <div class="row">
          <div class="col-6 p-0">
            <div class="item-wrapper size-3" [style.background]="'url(' + items[3].image + ')'">
              <div class="item-back"></div>
              <div class="item-info">

                <div class="item-title">
                  <h2 class="ml-3 mt-3">{{items[3].title}}</h2>
                </div>
                <div class="animated-line ml-3 mt-3"></div>
                <div class="item-description mx-3 mt-3">
                  <span>{{items[3].description}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 p-0">
            <div class="item-wrapper size-3" [style.background]="'url(' + items[4].image + ')'">
              <div class="item-back"></div>
              <div class="item-info">

                <div class="item-title">
                  <h2 class="ml-3 mt-3">{{items[4].title}}</h2>
                </div>
                <div class="animated-line ml-3 mt-3"></div>
                <div class="item-description mx-3 mt-3">
                  <span>{{items[4].description}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0">
            <div class="item-wrapper size-4" [style.background]="'url(' + items[5].image + ')'">
              <div class="item-back"></div>
              <div class="item-info">
                <div class="item-title">
                  <h2 class="ml-4 mt-4">{{items[5].title}}</h2>
                </div>
                <div class="animated-line ml-4 mt-4"></div>
                <div class="item-description mx-4 mt-4">
                  <span>{{items[5].description}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</section>
