<section class="about-bcg" id="about">
  <div class="container-fluid container-lg">
    <h3 class="text-center p-4" appFadeInDown>About</h3>
    <div class="row">
      <div class="col-12 mb-5 text-left text-danger right-line">
        <p>Our mission:</p>
      </div>

      <div class="col-4" appFadeInLeft>
        <p>Solve ​<span class="text-blue">high-technology</span> problems which give an advantage to our customers
          over the competitors</p>
      </div>
      <div class="col-4" appFadeIn>
        <p>Enjoy​watching how our solutions facilitate work, <span class="text-blue">optimise</span> resources,
          improve/save people’s lives</p>
      </div>
      <div class="col-4" appFadeInRight>
        <p>Be proud of collaborative achievements & <span class="text-blue">financial</span> highlights of our
          clients</p>
      </div>

      <div class="col-12 col-md-5 block-with-shadow mt-5" appFadeInLeft>
        <div class="row">
          <div class="col-12 my-3 text-left text-danger right-line">
            <p>How it began</p>
          </div>

          <div class="col-12">
            <ul>
              <li>
                <b>Date of establishment:</b>
                6 August 2013
              </li>
              <li>
                <b>First specialization:</b>
                Interactive Technologies ** Kinect is the name of IR depth camera made by Microsoft. It is used in video
                games for tracking of body movements and position. It’s often used in interactive outdoor advertisement
                and exhibitions.
              </li>
              <li>
                <b>First project:</b>
                Taxi App (like Uber)
              </li>
              <li>
                <b>Longest project:</b>
                Video Streaming Solutions
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-7 calendar-about p-5 mt-5" appFadeInRight>
        <table>
          <tr>
            <th></th>
            <th>2013</th>
            <th>2014</th>
            <th>2015</th>
            <th>2016</th>
            <th>2017</th>
            <th>2018</th>
            <th>2019</th>
            <th>2020</th>
          </tr>
          <tr>
            <td>Projects</td>
            <td>2</td>
            <td>8</td>
            <td>15</td>
            <td>35</td>
            <td>57</td>
            <td>43</td>
            <td>38</td>
            <td>59</td>
          </tr>
          <tr>
            <td>Office space (m2)</td>
            <td>0</td>
            <td>24</td>
            <td>40</td>
            <td>240</td>
            <td>380</td>
            <td>760</td>
            <td>760</td>
            <td>380*</td>
          </tr>
          <tr>
            <td>Cups of coffee</td>
            <td>193</td>
            <td>1.8k</td>
            <td>3.5k</td>
            <td>6.5k</td>
            <td>9.3k</td>
            <td>12.7k</td>
            <td>15.4k</td>
            <td>2.5k</td>
          </tr>
        </table>
        <p class="p-3 h5">*In early 2019 we started turning our company to remote work. That allowed us to hire the best talents in
          Eastern Europe, not restricting ourselves to only one local region. When the COVID pandemic started in 2020 we
          were already prepared with our processes. Office spaces were reduced as well as mandatory attendance was
          canceled. We keep follow safety protocols and encourage WFH to not stop and 100% continue intensive work for
          our partners worldwide</p>
      </div>
    </div>

  </div>
</section>
