import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss']
})
export class ExpertiseComponent implements OnInit {

  imagePath = environment.url +  '/assets/image/expertise';

  items = [
    {
      title: 'Cloud technologies',
      description: 'Implement any kind of architecture using top cloud service providers (AWS, Azure, GCP, OpenShift), building private clouds with VMware, Kubernetes, Docker Swarm, Kong, Istio... Microservice or/and serverless architecture. SSO. CI/CD and DevOps. QA Automation, TDD/BDD.',
      image: this.imagePath + '/Infographics.png',
    }, {
      title: 'Internet of Things',
      description: 'Wearable devices & gadgets, medical devices, hardware components, wired & wireless protocols, prototyping & research',
      image: this.imagePath + '/04.jpg',
    }, {
      title: 'Privacy and Security',
      description: 'VPN, protocols, encryption; server infrastructure administration & automation; billing systems; mobile apps',
      image: this.imagePath + '/05.jpg',
    }, {
      title: 'Healthcare',
      description: 'Cardiology; diabetes, obesity & metabolic health; mental health; treatment & patient simulation; market research',
      image: this.imagePath + '/01.jpg',
    }, {
      title: 'AI & datasets',
      description: 'Machine learning, datasets gathering tools, and data crowdsourcing; Applicated in the fashion industry (outfit discover and design, analyze trends, computer vision)',
      image: this.imagePath + '/02.jpg',
    }, {
      title: 'Fintech',
      description: 'Our teammates participate as contractors in well-known fintech companies in Canada and the US. It\'s mostly remote staffing. However their contribution highly valuable. Positions: software architects, team leads, developers, DevOps, QA automation.',
      image: this.imagePath + '/06-1.jpg',
    },
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
