import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';

interface Member {
  name: string;
  position: string;
  image: string;
  skype: string;
  mail: string;
  linkedin: string;
}

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})

export class OurTeamComponent implements OnInit {
  imagePath = environment.url +  '/assets/image/members';

  team: Member[] = [
    {
      name: 'Anton Pinchuk',
      position: 'CEO',
      image: this.imagePath + '/avatar-4-120x120.jpg',
      skype: 'anton.pinchuk',
      mail: 'antony.pinchuk@gmail.com',
      linkedin: 'https://www.linkedin.com/in/antonpinchuk'
    },
    {
      name: 'MAKSYM SHAPOVALOV',
      position: 'CTO',
      image: this.imagePath + '/shapovalov-120x120.jpg',
      skype: 'makc_sh_93',
      mail: 'info@kinect.pro',
      linkedin: 'https://www.linkedin.com/in/maksym-shapovalov-83b55012a'
    },
    {
      name: 'YURI VOVKOTRUB',
      position: 'COO',
      image: this.imagePath + '/IMG_20191017_114232-e1573722877299-120x120.jpg',
      skype: 'greykrook',
      mail: 'yuri@kinect.pro',
      linkedin: 'https://www.linkedin.com/in/yurivovkotrub'
    },
    {
      name: 'Anna Bambuliak',
      position: 'BDM',
      image: this.imagePath + '/anna_2.png',
      skype: '24f0ae664d803175',
      mail: 'anna.bambuliak95@gmail.com',
      linkedin: 'https://www.linkedin.com/in/anna-bambuliak-5702411a6/'
    },
    {
      name: 'Inna Kholostova',
      position: 'BDM',
      image: this.imagePath + '/IMG-3181.png',
      skype: 'innakholostova',
      mail: 'innakholostova@gmail.com',
      linkedin: 'https://www.linkedin.com/in/inna-kholostova-9aa38a67/'
    },
    // {
    //   name: 'VLAD YAREMENKO',
    //   position: 'Business Developer',
    //   image: this.imagePath + '/250739084_20524-120x120.jpg',
    //   skype: 'live:o.ferton12',
    //   mail: 'o.ferton12@gmail.com',
    //   linkedin: 'https://www.linkedin.com/in/vladislav-yaremenko-32143a174/'
    // }
  ];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }


  skype(data) {
    return this.sanitizer.bypassSecurityTrustUrl(`skype:${data}`);
  }
}
