import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgpCarouselModule} from '@kinect-pro/ngp-carousel';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AngularTiltModule} from 'angular-tilt';
import {NgxSpinnerModule} from 'ngx-spinner';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import {HttpClientModule} from '@angular/common/http';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    NgpCarouselModule,
    SharedModule,
    SwiperModule,
    FontAwesomeModule,
    AngularTiltModule,
    NgxSpinnerModule,
    ScrollToModule.forRoot(),
    HttpClientModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
