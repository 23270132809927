<section class="testimonials-bcg pb-5">

  <div class="container-fluid container-lg">
    <h3 class="py-4 text-left">Testimonials</h3>

    <div class="row">
      <div class="col-12 col-md-6 mt-5">
        <div class="white">
          <swiper [config]=config>
            <div *ngFor="let item of testimonials" class="px-3 testimonials-wrapper swiper-slide" [class.animated]=""
                 [class.flipInY]="">
              <div class="testimonials-image text-center my-3 fadeIn">
                <img class="img-fluid" [src]="item.image" [alt]="item.name">
              </div>
              <div class="testimonials-text px-3 fadeIn" [innerHTML]="item.text"></div>
              <div class="testimonials-name text-center fadeIn">
                {{item.name}}
              </div>
              <div class="testimonials-company text-center fadeIn">
                {{item.company}}
              </div>
            </div>
          </swiper>
          <div class="btn-wrapper ">
            <div class="d-flex">
              <div class="col-6 button-prev px-0">
                <div class="testimonials-btn">Previous</div>
              </div>
              <div class="col-6 button-next px-0">
                <div class="testimonials-btn">Next</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 pt-5 pt-sm-0" appFlipInY>
        <div class="img-wrapper px-3">
          <img src="assets/logo/group.jpg" alt="">
        </div>
      </div>
    </div>
  </div>

</section>
