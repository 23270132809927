import {Component, OnChanges, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading = false;

  constructor(private meta: Meta, private spinner: NgxSpinnerService) {
    this.spinner.show();

    this.meta.addTag({ name: 'description', content: 'Outsource company with offices in Ukraine and Canada. Software development, ' +
        'web applications, Android and iOS applications.' });
  }
  title = 'Web and Mobile Development Company | Kinect.PRO';

  ngOnInit(): void {

    setTimeout(() => {
      this.spinner.hide();
      this.isLoading = true;
    }, 1000);
  }
}
