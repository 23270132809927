import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {MailerService} from '../../../core/services/mailer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();

  mailForm = new FormGroup({

    email: new FormControl('', [Validators.required, Validators.email]),

    name: new FormControl('', [Validators.required]),

    message: new FormControl('', Validators.required)

  });


  isTrySending = false;
  isSending = false;
  isSuccess = false;
  isError = false;

  constructor(private mailerService: MailerService) {
  }

  ngOnInit() {

  }

  sendMail($event, formDirective: FormGroupDirective) {
    this.isTrySending = true;
    this.isSuccess = false;
    this.isError = false;
    if (this.mailForm.valid) {
      this.isSending = true;
      this.mailerService.send(this.mailForm.value).subscribe((data: any) => {
        data = JSON.parse(data);
        this.isTrySending = false;
        this.isSending = false;
        if (data.status === 'success') {
          this.isSuccess = true;
          this.mailForm.reset();
          formDirective.resetForm();
        } else {
          this.isError = true;
        }
      }, error => {
        this.isTrySending = false;
        this.isError = true;
        this.isSending = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get f() {
    return this.mailForm.controls;
  }
}
