<section class="container-fluid container-lg">
  <div class="row">
    <div class="col-12 my-5">
      <div class="section-title">
        <h3 appFadeInDown>Technologies</h3>
      </div>
    </div>
    <div class="col-12">
      <div class="mb-5 text-left text-danger right-line">
        <p>We are working on</p>
      </div>
    </div>
    <div class="col-12">
      <ngp-carousel [loop]="true" [autoSlide]="3000">
        <ng-template *ngFor="let item of technologies" ngpCarouselItem>
          <img [src]="logoPath(item)" [alt]="item.name" [title]="item.name" class="logo">
        </ng-template>
      </ngp-carousel>
    </div>
  </div>
</section>
