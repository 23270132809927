<section class="position-relative">
  <img class="small-header-logo first-header-logo" src="/assets/logo/Logo-3.png">
  <img class="small-header-logo second-header-logo" src="/assets/logo/Logo-3.png">

  <div class="header-bcg">
    <div class="circle-decor"></div>
  </div>
  <div class="container-fluid container-lg">
    <div class="row">
      <div class="col-12 p-0">
        <app-header-menu></app-header-menu>
      </div>
      <div class="title col-md-9 col-lg-8 col-12 left-pad content-top-margin title-padding pt-3">
        <div class="mw-100 row">
          <div class="col-10 col-sm-7 col-md-7 col-lg-12">
            <h2>SOFTWARE DEVELOPMENT</h2>
            <span>CONSULTING AGENCY</span>
          </div>
          <div class="text-block text-top-margin col-12 col-sm-5 col-lg-12">
            <div class="row">
              <div class="col-lg-6 col-12">
                <h3>Cloud-based</h3>
                <p>web</p>
              </div>

              <div class="col-lg-6 col-12">
                <h3>Mobile Apps</h3>
                <p>Iot</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="d-none d-md-block col-md-3 col-lg-4 big-logo content-top-margin pt-3" aTilt [tiltSettings]="tiltSettings">
        <img class="float-right" [src]="'/assets/logo/big-logo.svg'"
             style="will-change: transform; transform: perspective(300px) rotateX(0deg) rotateY(0deg);"
             alt="KinectPro logo">
      </div>
    </div>

    <div class="row">
      <div id="services-block" class="services-block shadow">
        <div class="service-item text-center" *ngFor="let item of services | keyvalue">
          <div class="service-number px-1">
            <span>0{{+item.key + 1}}</span>
          </div>
          <div class="service-name">
            <span>{{item.value.name}}</span>
          </div>
          <div class="service-description px-3">
            <span>{{item.value.description}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
