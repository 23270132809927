import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailerService {

  url = 'mail.php';

  constructor(private http: HttpClient) {
  }

  send(val) {
    return this.http.post(
      this.url,
      {...val},
      {responseType: 'text'});
  }
}
