<nav class="navbar navbar-expand-lg navbar-light justify-content-between px-0 header-shadow">
  <button class="navbar-toggler ml-3 border-0" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
          (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span class="toggler-icon">
      <fa-icon [icon]="faBars" *ngIf="isCollapsed"></fa-icon>
      <fa-icon [icon]="faTimes" *ngIf="!isCollapsed"></fa-icon>
    </span>
  </button>
  <a class="navbar-brand" href="#">
    <img src="/assets/image/logo-svg.svg" class="main-logo" alt="Kinect.Pro"
         itemprop="logo">
  </a>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup" [ngbCollapse]="isCollapsed" [class.menu-shadow]="!isCollapsed">
    <div class="navbar-nav ml-auto">
      <a class="nav-item nav-link active" [ngx-scroll-to]="'#services-block'">SERVICES</a>
      <a class="nav-item nav-link" [ngx-scroll-to]="'#expertise'">EXPERTISE</a>
      <a class="nav-item nav-link" [ngx-scroll-to]="'#our-work'">OUR WORK</a>
      <a class="nav-item nav-link" [ngx-scroll-to]="'#about'">ABOUT</a>
      <a class="nav-item nav-link" [ngx-scroll-to]="'#footer'">CONTACT US</a>
      <div class="d-flex flex-nowrap m-auto">
        <a class="nav-item nav-link mr-2" href="http://facebook.com/kinect.pro">
          <fa-icon [icon]="faFacebookF"></fa-icon>
        </a>
        <a class="nav-item nav-link ml-2" href="https://ru.linkedin.com/company/kinect-pro">
          <fa-icon [icon]="faLinkedinIn"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</nav>
