<section class="mb-5" id="our-work">
  <div class="container-fluid container-lg">
    <h3 class="text-center p-4" appFadeInDown>Our Work</h3>
    <div class="row">
      <div class="col-12 mb-5 text-left text-danger right-line">
        <p>Task:</p>
      </div>
      <div class="col-12">
        <swiper [config]=config>

          <div class="swiper-slide" *ngFor="let item of works">
            <div class="row">
              <div class="col-12 col-sm-6 p-0">
                <div class="text-wrapper">
                  <h4 class="text-left title">{{item.title}}</h4>
                  <div class="pl-3 description" [innerHTML]="item.description"></div>
                </div>
              </div>
              <div class="col-12 col-sm-6 p-0">
                <div class="img-wrapper" [style.background]="'url(' + item.image + ')'"></div>
              </div>
              <div class="col-12 col-sm-10 col-md-8 shadow table-wrapper">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="row">
                      <div class="col-12 table-title black text-center">
                        CHALLENGE
                      </div>
                      <div class="col-12 table-text challenge">
                        {{item.challenge}}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="row">
                      <div class="col-12 table-title blue text-center">
                        Result
                      </div>
                      <div class="col-12 table-text table-text-border result">
                        {{item.result}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </div>
  </div>


</section>
