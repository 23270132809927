import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {

  constructor() {
  }

  imagePath = environment.url +  '/assets/image/logo';

  technologies = [
    {
      image: 'AWS-81.png',
      name: 'Amazon Web Services'
    }, {
      image: 'social-icon-google-cloud-1200-630.png',
      name: 'Google Cloud Platform'
    }, {
      image: 'microsoft-azure-cloud-2156-1120.jpg',
      name: 'Microsoft Azure cloud'
    }, {
      image: 'OpenShift.png',
      name: 'OpenShift'
    }, {
      image: 'Kubernetes_(container_engine).png',
      name: 'Kubernetes'
    }, {
      image: 'vmware.jpg',
      name: 'VMware virtualization'
    }, {
      image: 'kong-community-team_512x512.png',
      name: 'Kong dynamic request routing'
    }, {
      image: 'Istio.jpg',
      name: 'Istio service mesh'
    }, {
      image: 'jenkins-logo-png-4.png',
      name: 'Jenkins continuous integration and continuos delivery'
    }, {
      image: 'og-image-8b3e4f7d.png',
      name: 'Terraform cloud automation'
    }, {
      image: 'github_PNG58.png',
      name: 'Github'
    }, {
      image: '1_Voh7k0_lP6wMJEApsqfKnA.png',
      name: '.NET Framework'
    }, {
      image: 'java.png',
      name: 'Java'
    }, {
      image: 'spring_boot_logo.png',
      name: 'SpringBoot'
    }, {
      image: '66NFe2g3.jpg',
      name: 'Hibernate'
    }, {
      image: 'JHipster-logo.png',
      name: 'Jhipster'
    }, {
      image: 'nodejslogo.png',
      name: 'NodeJS microservices'
    }, {
      image: '220px-Laravel.svg.png',
      name: 'Laravel PHP backend development'
    }, {
      image: '1200px-React-icon.svg.png',
      name: 'ReactJS reactive Frontend development'
    }, {
      image: 'Angular_full_color_logo.svg.png',
      name: 'Angular single-page applications'
    }, {
      name: 'Ionic cross-platform application development',
      image: 'Ionic.png'
    }, {
      name: 'WordPress content management system',
      image: 'wp.png'
    }, {
      name: 'Shopify e-commerce solutions',
      image: 'Shopify.png'
    }, {
      name: 'Android native application',
      image: 'Android.png'
    }, {
      name: 'iOS - Swift & Objective-C',
      image: '1200px-Apple_logo_black.svg.png'
    }, {
      name: 'Flutter cross-platform application development',
      image: 'Flutter.png'
    },
  ];

  ngOnInit() {
  }

  logoPath(item) {
    return this.imagePath + '/' + item.image;
  }
}
