<section class="container-fluid container-lg">
  <div class="row mb-5">
    <div class="col-12 my-5">
      <div class="section-title">
        <h3 appFadeInDown>Our Team</h3>
      </div>
    </div>

    <div class="col-12">
      <div class="row justify-content-around">
        <div *ngFor="let member of team" class="col-5 col-sm-4 col-md-3 col-lg-2 p-1 w-100 p-2 m-auto m-sm-0">
          <div class="p-2 elem-item card">
            <div class="card-img-top member-image mt-4">
              <img [src]="member.image" [alt]="member.name">
            </div>
            <div class="card-body px-0">
              <div class="member-name text-uppercase text-center mb-2 px-2">
                <h4>{{member.name}}</h4>
              </div>
              <div class="member-position text-center mb-5">
                <span>{{member.position}}</span>
              </div>
              <div class="member-contacts text-center px-4">
                <div *ngIf="member.skype" class="link-skype">
                  <a [href]="skype(member.skype)"></a>
                </div>
                <div *ngIf="member.mail" class="link-gmail">
                  <a href="mailto:{{member.mail}}"></a>
                </div>
                <div *ngIf="member.linkedin" class="link-linkedin">
                  <a href="{{member.linkedin}}"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
