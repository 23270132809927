import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from './components/footer/footer.component';
import {OurTeamComponent} from './components/our-team/our-team.component';
import { TechnologiesComponent } from './components/technologies/technologies.component';
import {NgpCarouselModule} from '@kinect-pro/ngp-carousel';
import { AboutComponent } from './components/about/about.component';
import {TestimonialsComponent} from './components/testimonials/testimonials.component';
import { OurWorkComponent } from './components/our-work/our-work.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import { ExpertiseComponent } from './components/expertise/expertise.component';
import { ExpertiseItemComponent } from './components/expertise/expertise-item/expertise-item.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderMenuComponent } from './components/header/header-menu/header-menu.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { FadeInDownAnimationDirective } from './directives/fade-in-down-animation.directive';
import { FadeInLeftDirective } from './directives/fade-in-left.directive';
import { FadeInDirective } from './directives/fade-in.directive';
import { FadeInRightDirective } from './directives/fade-in-right.directive';
import { FlipInYDirective } from './directives/flip-in-y.directive';
import { FlipInXDirective } from './directives/flip-in-x.directive';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AngularTiltModule} from 'angular-tilt';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    FooterComponent,
    OurTeamComponent,
    TechnologiesComponent,
    AboutComponent,
    TestimonialsComponent,
    OurWorkComponent,
    ExpertiseComponent,
    ExpertiseItemComponent,
    HeaderComponent,
    HeaderMenuComponent,
    FadeInDownAnimationDirective,
    FadeInLeftDirective,
    FadeInDirective,
    FadeInRightDirective,
    FlipInYDirective,
    FlipInXDirective
  ],
  imports: [
    CommonModule,
    NgpCarouselModule,
    SwiperModule,
    FontAwesomeModule,
    NgbModule,
    AngularTiltModule,
    ScrollToModule,
    ReactiveFormsModule
  ],
  exports: [
    FooterComponent,
    OurTeamComponent,
    TechnologiesComponent,
    AboutComponent,
    TestimonialsComponent,
    OurWorkComponent,
    ExpertiseComponent,
    HeaderComponent
  ],
})
export class SharedModule { }
