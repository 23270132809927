import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[appFlipInX]'
})
export class FlipInXDirective {
  isMoving = false;
  constructor(public el: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.el.nativeElement.getBoundingClientRect().top - window.screen.height <= 0 && !this.isMoving) {
      this.isMoving = true;
      this.renderer.addClass(this.el.nativeElement, 'animated');
      this.renderer.addClass(this.el.nativeElement, 'flipInX');
    }
  }
}
