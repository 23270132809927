import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() {
  }

  services = [
    {
      name: 'From idea to MVP',
      description: 'with our creative developers and UX'
    }, {
      name: 'Scale your team',
      description: 'from the pool of talented engineers'
    }, {
      name: 'Deliver high quality',
      description: 'ISTQB certified QA and DevOps'
    }, {
      name: 'Face-to-face',
      description: 'Agile approach and principles'
    }, {
      name: 'Up to 25 years of XP',
      description: 'european mentality and education'
    },
  ];

  tiltSettings = {
    max: 15,
    scale: 1.05,
    perspective: 300,
  };

  ngOnInit() {
  }

}
