import {Component, OnInit} from '@angular/core';
import {faFacebookF, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {

  constructor() {
  }

  faFacebookF = faFacebookF;
  faLinkedinIn = faLinkedinIn;
  faBars = faBars;
  faTimes = faTimes;
  isCollapsed = true;

  ngOnInit() {
  }

}
